body {
    font-family: tahoma, sans-serif;
    background-color: #282C34;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.title-bloc {
    margin: 0;
    background-color: #32363E;
    padding: 15px 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

h1 {
    font-weight: 800;
    font-size: 23px;
    text-align: center;
    color: #61DAFB;
}

h1 .fa-react {
    font-size: 40px;
    font-weight: 400;
    margin-right: 15px;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 20px;
    max-width: 1024px;
    color: #ffffff;
}

#root p {
    font-family: 'Nothing You Could Do', cursive;
    font-size: 55px;
    text-align: justify;
    padding: 5px 30px;
    margin: 100px auto 50px auto;
    min-height: 400px;
}

#root span {
    text-align: right;
    font-weight: 800;
    color: #61DAFB;
    display: block;
    margin-top: 50px;
}

button {
    font-family: tahoma, sans-serif;
    font-weight: 800;
    font-size: 20px;
    color: white;
    background-color: rgba(97, 218, 251, 0.7);
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.5);
    transition: 0.1s;
    cursor: pointer;
    outline: none;
}

button:hover {
    color: #61DAFB;
    background-color: #32363E;
    transform: translateY(5px);
}

button:active {
    box-shadow: 0px 0px 20px rgba(97, 218, 251, 0.6);
}

/*-------------------- Media Queries Smartphone -------------------*/
@media all and (max-device-width: 480px) {
    .title-bloc {
        padding: 25px 20px;
    }

    h1 {
        font-size: 22px;
        text-align: center;
    }

    #root p {
        font-size: 40px;
        text-align: justify;
        margin: 160px auto 50px auto;
    }

    button {
        display: block;
        width: 70%;
        padding: 20px;
        margin: auto;
    }
}